$(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('.toast').toast();
});

var $navigationLinks = $('#navbarResponsive > ul > li');

// if you don't want to throttle the function use this instead:
// $(window).scroll( highlightNavigation );
$(".navmenu .navbar-nav a").click(function () {
    $("#mobile-menu").offcanvas('hide');
});

$(".menu-close").on('click', function (e) {
    $('.navmenu').offcanvas("hide");
    //if (!data) return e.preventDefault()
});

$(document).ready(function () {
// scroll to top
    $(() => {
        const toTop = $('#toTop');
        if (toTop.length) {
            const scrollTrigger = 100, // px
                backToTop = function () {
                    let scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        toTop.addClass('show');
                    } else {
                        toTop.removeClass('show');
                    }
                };
            backToTop();
            $(window).on('scroll', function () {
                backToTop();
            });
            toTop.on('click', function (e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 800);
            });
        }
    });

    $(function () {
        var v = $(window).width();
        if (v >= 1024) {
            new WOW().init();
        }
    });

    const carousel = $('#siteHeader');
    const headerContainer = $('#headerContainer');
    const deviceWidth = $(window).width();
    carousel.hover(
        function () {
            if (deviceWidth >= 992)
                headerContainer.show();
        },
        function () {
            if (deviceWidth >= 992)
                headerContainer.hide();
        }
    );
    if (deviceWidth >= 992) {
        carousel.on("touchstart", function (e) {
            e.preventDefault();
            //console.log('You touched my talala');
            headerContainer.toggle();
        });
    }

    //lazyload();

    let ver = Math.floor(Math.random() * 100);
    //$("head").append("<link rel='stylesheet' type='text/css' href='css/main.css?v=" + ver + "' />");
    $("head").append("<link rel='stylesheet' type='text/css' href='/wp-content/themes/maria/css/main.css?v=" + ver + "' />");
});

window.onload = function () {
    setInterval(function () {
        $(".se-pre-con").fadeOut("1000");
    }, 1000);
};

